
const BASE_API = 'https://qual-services.sengup.com';
export const environment = {
    production: true,
    name: 'preprod',
    walletApi: BASE_API + '/wallet_services/api',
    mauritanieApi : BASE_API + '/mauritanie_services/api',
    apiDgpsn: BASE_API + '/dgpsn_services/api',
    apiAuthUrl: BASE_API + '/auth_services/api',
    apiAdminUrl: BASE_API + '/admin_services/api',
    apiTransfertUrl: BASE_API + '/transfert_services/api',
    apiReseauUrl: BASE_API + '/reseau_services/api',
    apiBourseE: BASE_API + '/digimp_services/api',
    apiPas: BASE_API + '/pasm_services/api',
    apiDgse: BASE_API + '/dgse_services/api',
    apiAssurance: BASE_API + '/assurance_services/api'
};
