import { Component, OnInit } from "@angular/core";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Entite } from "src/app/shared/models/Entite";

@Component({
  selector: "app-header-sidebar-compact",
  templateUrl: "./header-sidebar-compact.component.html",
  styleUrls: ["./header-sidebar-compact.component.scss"]
})
export class HeaderSidebarCompactComponent implements OnInit {
  notifications: any[];
  entite: Entite;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService
  ) {
    this.notifications = [];
  }

  ngOnInit() {
    this.auth.currentEntity.subscribe(entite => this.entite = entite);
  }


  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
    this.navService.changeSidenavOpen(state.sidenavOpen);
  }

}
