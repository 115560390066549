import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  messages=[];
  loading;
  totalElements;
  query = '?status=1';
  constructor(  private auth: AuthService) { }

  ngOnInit() {
    this.getMessage();

  }
  getMessage(){
    this.loading = true;
    this.auth.getMessageEncours(1, this.query).subscribe(res => {
      this.messages=  res['hydra:member'];
      this.totalElements =  res['hydra:totalItems'];
      this.loading = false;
    },error => {
       console.log(error)
        this.loading = false;
      }
      );

  }

  getMMessge(messages: any) {
   return  messages.map(_=>_.libelle).join(" --- ")
  }
}
