export class Entite {
  id: number;
  codeEntite: string;
  couleur: string;
  compteEntite:any;
  raisonSociale: string;
  email: string;
  tel: string;
  adresse: string;
  pays: string;
  ninea: string;
  rc: string;
  secteurActivite: string;
  devise: string;
  photo: string;
  source: string;
  fileType: string;
  status: boolean;
  dateCreation: string;
  dateModification:string;

  constructor(obj:any) {
    this.id = obj.id || null;
    this.codeEntite = obj.codeEntite || '';
    this.couleur = obj.couleur || '';
    this.compteEntite = obj.compteEntite || {};
    this.raisonSociale = obj.raisonSociale || '';
    this.email = obj.email || '';
    this.tel = obj.tel || '';
    this.adresse = obj.adresse || '';
    this.pays = obj.pays || '';
    this.ninea = obj.ninea || '';
    this.rc = obj.rc || '';
    this.secteurActivite = obj.secteurActivite || '';
    this.devise = obj.devise || '';
    this.photo = obj.photo || '';
    this.source = obj.source || '';
    this.fileType = obj.fileType || '';
    this.status = obj.status || false;
    this.dateCreation = obj.dateCreation || '';
    this.dateModification = obj.dateModification || '';
  }

  public get entete():string {
    const ninea ='NINEA: ' + this.ninea; 
    const adresse = 'Adresse: ' + this.adresse;
    const email = 'Email: ' + this.email;
    const tel = 'Tel: ' + this.tel;
    return [this.raisonSociale,ninea, adresse, email, tel].join(', ')
  }
}