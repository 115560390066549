export class Caisse{
  id: number;
  code: string;
  numero: number;
  solde: number;
  seuilAlerte: number;
  pointDeVente:{};

 constructor(obj:any) {
   this.id = obj.id || null;
   this.code = obj.code || '';
   this.numero = obj.numero || '';
   this.solde = obj.solde || 0;
   this.seuilAlerte = obj.seuilAlerte || 0; 
   this.pointDeVente = obj.pointDeVente || {};
 }
}