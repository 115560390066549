import { Component } from '@angular/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {AuthService} from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bootDash';
  constructor(private bnIdle:BnNgIdleService,private authService:AuthService) {
    this.bnIdle.startWatching(1500).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.authService.signout();
      }
      else {
        this.bnIdle.resetTimer()
        console.log('tesr')
      }
    });
  }

}
