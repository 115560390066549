import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // console.log('error',err);
            // const etat = parseInt(err.status);
            // console.log('uppppppp', err)
            // if (etat == 401 || etat == 403) {
            if (err.error.message === 'Username could not be found.' || err.error.message === 'Authentication Required'
            || err.error.message === 'An authentication exception occurred.') {
               
               this.authService.signout();
            //    this.authService.setAuthenticated(false);
            //    location.reload(true);
            //    return;
            }
            // console.log(err);
            // const error = err.statusText; 
            return throwError(err);
        }));
    }
}