export class User {
  id: number;
  username: string;
  codeClient: string;
  codeSociete: string;
  email: string;
  nom: string;
  prenom: string;
  tel: string;
  agence: string;
  caisse: string;
  nomAgence: string;
  photo: string;
  source: string;
  profils: [];
  fileType: string;
  status: true;
  changePassword: true;
  dateCreation: string;
  firstLogin: string;
  lastLogin: string;
  dateModification: string;

  constructor(obj:any) {
    this.id = obj.id || '';
    this.username = obj.username || '';
    this.codeClient = obj.codeClient || '';
    this.codeSociete = obj.codeSociete || '';
    this.email = obj.email || '';
    this.nom = obj.nom || '';
    this.prenom = obj.prenom || '';
    this.tel = obj.tel || '';
    this.agence = obj.agence || {};
    this.caisse = obj.caisse || '';
    this.nomAgence = obj.nomAgence || '';
    this.photo = obj.photo || '';
    this.source = obj.source || '';
    this.profils = obj.profils || [];
    this.fileType = obj.fileType || '';
    this.status = obj.status || false;
    this.changePassword = obj.changePassword || '';
    this.dateCreation = obj.dateCreation || '';
    this.firstLogin = obj.firstLogin || '';
    this.lastLogin = obj.lastLogin || '';
    this.dateModification = obj.dateModification || '';
  }

  public get fullName():string{
    return [this.prenom, this.nom].join(' ')
    .toString()
    .toLocaleLowerCase();
  }
  public get roles():string{
    return this.profils.join(' ')
    .toString()
    .toLocaleLowerCase();
  }

}