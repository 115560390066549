import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Constantes} from '../constantes';
import {AuthService} from './auth.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}

export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    entite;
    private sidenavOpenSource = new BehaviorSubject(true);
    sidenavOpen = this.sidenavOpenSource.asObservable();

    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };

    constructor(private _authService:AuthService) {

    }


    menu = {
        default:[
            {
                name: 'Accueil ',
                type: 'link',
                icon: 'i-Home1',
                state: '/home'
            },
            // {
            //     name: 'Cartes',
            //     type: 'link',
            //     icon: 'i-Credit-Card',
            //     state:'/cartes'
            // },
            {
                name: 'Clients',
                type: 'link',
                icon: 'i-Business-Mens',
                state: '/clients'
            },
            {
                name: 'Transactionn',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions/tous'
            },
            {
                name: 'Historique transfert',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions/home-transfert'
            },
            {
                name: 'Reporting',
                type: 'link',
                icon: 'i-Statistic',
                state: '/reporting'
            },
            {
                name: 'Appro',
                type: 'link',
                icon: 'i-Financial',
                state: '/services/appro'
            }, {
                name: 'Appel de fond',
                type: 'link',
                icon: 'i-Money-2',
                state: '/services/reversement'
            },
            // {
            //     name: 'Conformite',
            //     type: 'link',
            //     icon: 'i-Files',
            //     state: '/services/conformite'
            // },
            // {
            //     name: 'Fonds',
            //     type: 'link',
            //     icon: 'i-Money',
            //     state: '/refunds'
            // },
            {
                name: 'Support',
                type: 'link',
                icon: 'i-Support',
                state: '/services/osticket'
            },
        ],
        digicash:[
            {
                name: 'Accueil ',
                type: 'link',
                icon: 'i-Home1',
                state: '/home'
            },
            // {
            //     name: 'Cartes',
            //     type: 'link',
            //     icon: 'i-Credit-Card',
            //     state:'/cartes'
            // },
            {
                name: 'Clients',
                type: 'link',
                icon: 'i-Business-Mens',
                state: '/clients'
            },
            {
                name: 'Transactionn',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions/tous'
            },
            {
                name: 'Historique transfert',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions/home-transfert'
            },
            {
                name: 'Reporting',
                type: 'link',
                icon: 'i-Statistic',
                state: '/reporting'
            },
            {
                name: 'Appro',
                type: 'link',
                icon: 'i-Financial',
                state: '/services/appro'
            }, {
                name: 'Appel de fond',
                type: 'link',
                icon: 'i-Money-2',
                state: '/services/reversement'
            },
            // {
            //     name: 'Conformite',
            //     type: 'link',
            //     icon: 'i-Files',
            //     state: '/services/conformite'
            // },
            // {
            //     name: 'Fonds',
            //     type: 'link',
            //     icon: 'i-Money',
            //     state: '/refunds'
            // },
            {
                name: 'Support',
                type: 'link',
                icon: 'i-Support',
                state: '/services/osticket'
            },
        ],
        lonase:[
            {
                name: 'Accueil ',
                type: 'link',
                icon: 'i-Home1',
                state: '/home'
            },
            {
                name: 'Cartes',
                type: 'link',
                icon: 'i-Credit-Card',
                state:'/cartes'
            },
            {
                name: 'Etats journaliers',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions'
            },
            {
                name: 'Reporting',
                type: 'link',
                icon: 'i-Statistic',
                state: '/reporting'
            },
            {
                name: 'Appro',
                type: 'link',
                icon: 'i-Financial',
                state: '/services/appro'
            },
            // {
            //     name: 'Reversement',
            //     type: 'link',
            //     icon: 'i-Coins',
            //     state: '/services/reversement'
            // },
            {
                name: 'Fermeture caisse',
                type: 'link',
                icon: 'i-Lock-2',
                state: '/services/fermeture'
            },
            {
                name: 'Support',
                type: 'link',
                icon: 'i-Support',
                state: '/services/osticket'
            },

        ],
        laposte:[
            {
                name: 'Accueil ',
                type: 'link',
                icon: 'i-Home1',
                state: '/home'
            },
            // {
            //     name: 'Cartes',
            //     type: 'link',
            //     icon: 'i-Credit-Card',
            //     state:'/cartes'
            // },
            {
                name: 'Transactionn',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions/tous'
            },
            {
                name: 'Historique transfert',
                type: 'link',
                icon: 'i-Sync',
                state: '/transactions/home-transfert'
            },
            {
                name: 'Reporting',
                type: 'link',
                icon: 'i-Statistic',
                state: '/reporting'
            },
            {
                name: 'Appro',
                type: 'link',
                icon: 'i-Financial',
                state: '/services/appro'
            },
            {
                name: 'Transfert UV',
                type: 'link',
                icon: 'i-Coins',
                state: '/services/reversement'
            },
            {
                name: 'Fermeture caisse',
                type: 'link',
                icon: 'i-Lock-2',
                state: '/services/fermeture'
            },
            {
                name: 'Support',
                type: 'link',
                icon: 'i-Support',
                state: '/services/osticket'
            },

        ]
    }
    defaultMenu: IMenuItem[] = [
        {
            name: 'Accueil ',
            type: 'link',
            icon: 'i-Home1',
            state: '/home'
        },
        {
          name: 'Cartes',
          type: 'link',
          icon: 'i-Credit-Card',
          state:'/cartes'
        },
        // {
        //     name: 'Clients',
        //     type: 'link',
        //     icon: 'i-Business-Mens',
        //     state: '/clients'
        // },
        {
            name: 'Transactionn',
            type: 'link',
            icon: 'i-Sync',
            state: '/transactions/tous'
        },
        {
            name: 'Historique transfert',
            type: 'link',
            icon: 'i-Sync',
            state: '/transactions/home-transfert'
        },
        // {
        //     name: 'Brouillard de caisse',
        //     type: 'link',
        //     icon: 'i-Sync',
        //     state: '/transactions'
        // },
        {
            name: 'Reporting',
            type: 'link',
            icon: 'i-Statistic',
            state: '/reporting'
        },
        {
            name: 'Appro',
            type: 'link',
            icon: 'i-Financial',
            state: '/services/appro'
        },
        {
            name: 'Reversement',
            type: 'link',
            icon: 'i-Coins',
            state: '/services/reversement'
        },
        {
            name: 'Fermeture caisse',
            type: 'link',
            icon: 'i-Lock-2',
            state: '/services/fermeture'
        },
        {
            name: 'Support',
            type: 'link',
            icon: 'i-Support',
            state: '/services/osticket'
        },
        // {
        //     name: 'Fermer la caisse',
        //     type: 'link',
        //     icon: 'i-Lock-2',
        //     state: '/home'
        // },
    ];


    // sets iconMenu as default;
   // menuItems = new BehaviorSubject<IMenuItem[]>( this.menu[window['entite']] );
    entity = JSON.parse(localStorage.getItem('guichet_current_user')).codeClient
    menuItems=  new BehaviorSubject<IMenuItem[]>( this.menu[ ['digicash','lonase','laposte'].includes(this.entity) ? this.entity : "default" ] ) ;
    //  init(){
    //  this.menuItems =    new BehaviorSubject<IMenuItem[]>( this.menu[window['entite']] );
    // }
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
    changeSidenavOpen(value) {
        this.sidenavOpenSource.next(value);
    }
    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
